"use client"

import { useLayoutEffect, useRef } from "react"

/**
 * This is a component that coordinates the loading of custom scrollbar CSS
 * If the browser has zero-width default scrollbars, we don't need to load
 * custom CSS, otherwise we load it.
 */
export const CustomScrollLoader = () => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    if (ref.current?.offsetWidth !== ref.current?.clientWidth) {
      // @ts-expect-error: Untyped import
      import("@/styles/custom-scroll.css")
    }
  }, [])

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        top: -10,
        width: 100,
        height: 10,
        overflow: "scroll",
      }}
    />
  )
}
