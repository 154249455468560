"use client"

import { ReactNode } from "react"
import { SessionProvider } from "next-auth/react"
import { Session } from "next-auth"

export interface AuthContextProps {
  children: ReactNode
  session?: Session | null
}

export default function AuthContext({ children, session }: AuthContextProps) {
  return (
    <SessionProvider
      session={session}
      refetchInterval={300}
      refetchOnWindowFocus
    >
      {children}
    </SessionProvider>
  )
}
