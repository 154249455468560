"use client"

import { useEffect } from "react"

export default function VersionReporter() {
  useEffect(() => {
    console.info(
      `%cℹ️ App version: %c${process.env.NEXT_PUBLIC_APP_VERSION}`,
      "color: dodgerblue;",
      "font-weight: 700; color: royalblue"
    )
  }, [])
  return null
}
